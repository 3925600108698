import {
  delay,
  exhaustMap,
  firstValueFrom,
  Observable,
  repeat,
  takeWhile,
} from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCRUDApiClient } from '@do/app-base-components';
import { SKIP_INTERCEPT_HEADER } from '@do/app-error';
import { CinemaDto, CinemaStatusDto } from '@do/common-dto';

import { ApiClientConfig } from '../api-client-config';

@Injectable({ providedIn: 'root' })
export class CinemaApiClient extends BaseCRUDApiClient<CinemaDto> {
  baseUrl = this.config.apiEndpoint + '/cinema';

  constructor(http: HttpClient, private config: ApiClientConfig) {
    super(http);
  }

  status(id: string) {
    return this.http.get<CinemaStatusDto>(`${this.baseUrl}/${id}/status`, {
      headers: new HttpHeaders().set(SKIP_INTERCEPT_HEADER, '1'),
    });
  }

  statusPromise(id: string) {
    return firstValueFrom(this.status(id));
  }

  loadFirstCinemaStatus(
    cinemaId: string,
    timeoutMs: number
  ): Observable<CinemaStatusDto> {
    const dateToCheck = new Date();

    // Definisco il predicato per il polling
    const predicate = (data: CinemaStatusDto) =>
      new Date().getTime() < dateToCheck.getTime() + timeoutMs &&
      data.masterControlUnitsStatus.some(
        (m) => m.lastUpdateAt && new Date(m.lastUpdateAt) < dateToCheck
      );

    // Creo l'observable per il polling
    return this.status(cinemaId).pipe(
      // Avvio un nuovo polling solo dopo il termine del precedente
      exhaustMap(() =>
        this.status(cinemaId).pipe(
          repeat({ delay: 1000 }),
          // Continuo il polling finché predicate = true
          takeWhile(predicate, true)
        )
      )
    );
  }
}
